import React from 'react';

export default function Quiz(props) {
    return (
        <div className="intro-page">
        <img src="blob-5.png" alt ="" className="blob5"/>
        <img src="blobs.png"  alt ="" className="blobs"/>
        <div>
            <h3 className="quizzical">Quizzical</h3>
            <p className="description">Test your knowledge by taking a quiz</p>
            <button className="start-quiz-btn" onClick={props.handleClick}>Start quiz</button>
        </div>
        
        </div>
    )
}