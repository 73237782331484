import React, {useState, useEffect} from 'react';
import Question from './Question'
import {nanoid} from 'nanoid';

export default function Questionnaire() {
    
const [questions, setQuestions] = useState([]);
const [answerSubmitted, setAnswerSubmitted] = useState(false);
const [correctAnswers, setCorrectAnswers] = useState(0);
    
useEffect(()=> {
     getNewQuizQuestions();
},[])

function handleChange(event,id) {
    const {value} = event.target;
    setQuestions(prevList => prevList.map(question=> {
        return question.id===id ? {...question, selectedAnswer: value} : question 
    }))
    
}

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
    array[randomIndex], array[currentIndex]];
    }
    return array;
}

function checkAnswers() {
    setAnswerSubmitted(true);
    const countArray = questions.filter(question=>{
        return question.correct_answer === question.selectedAnswer;
    })
    console.log(countArray.length);
    setCorrectAnswers(countArray.length);
}

function playAgain(){
    setQuestions([]);
     getNewQuizQuestions();
      setAnswerSubmitted(false);
}

function getNewQuizQuestions() {
    fetch("https://opentdb.com/api.php?amount=10&category=9")
    .then(res=> res.json())
    .then(data=> {
        const newQuestionObj = data.results.map(data=> {
            return {
                ...data, id : nanoid(),
                "selectedAnswer" : "",
                "totalOptions":shuffle([...data.incorrect_answers, data.correct_answer])
                }
        })
        setQuestions(newQuestionObj)
    })
}

const questionsTemplate = questions.map(question=>(
        <Question 
        key={question.id}
        questions={{...question}} 
        answerSubmitted={answerSubmitted}
        handleChange={handleChange}/>
    ))
    
       return (
        <div className="intro-page-q">
            {questionsTemplate}
            <div className="btn-container">
               {questions.length>0 && !answerSubmitted && <button className="submit-btn" onClick={checkAnswers}>Check answers</button>}
                {answerSubmitted && <p className="score-text">You scored {correctAnswers}/{questions.length} correct answers</p>}
                {answerSubmitted && <button className="submit-btn" onClick={playAgain}>Play again</button>}
            </div>

        </div>
       )
}