import React from 'react';

export default function Question(props) {
    const {
        id, 
        question, 
        correct_answer, 
        selectedAnswer, 
        totalOptions} = props.questions;

    const optionsTemplate = totalOptions.map(answer=>(
            <li>
                <input 
                
                type="radio"
                id={answer+'-'+id}
                name={id}
                value={answer}
                checked={selectedAnswer===answer}
                onChange={(event)=>props.handleChange(event,id)}
                />
                <label htmlFor={answer+'-'+id} 
                className={
                (props.answerSubmitted ? "submitted": "") +
                (props.answerSubmitted && correct_answer === answer ? " correct-answer": "") +
                (props.answerSubmitted && selectedAnswer!== correct_answer && selectedAnswer===answer ? " incorrect-answer": "")
                }   
                >{answer}</label>
            </li>
    ))


    function parseHtmlEntities(str) { 
        return str.replace(/&#([0-9]{1,4});/gi, function(match, numStr)
        { 
            var num = parseInt(numStr, 10); 
            // read num as normal number 
            return String.fromCharCode(num); 
        }); 
    }
    
    return (
        <div className="question-container">
        <h5 className="quiz-question">{parseHtmlEntities(question)}</h5>
        <ul className="options">
           {optionsTemplate}
        </ul>
        <hr className="hr-line"/>
        </div>
    )
}