import React, {useState} from 'react';
import Quiz from './Quiz';
import Questionnaire from './Questionnaire';

export default function App() {
    const [startQuiz, setStartQuiz] = useState(false);
    
    function startQuizClicked() {
        setStartQuiz(true);
    }
    
    return (
        <div>
            {!startQuiz && <Quiz handleClick={startQuizClicked}/>}
            {startQuiz &&  <Questionnaire /> }
        </div>
    )
}